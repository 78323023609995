"use client";
import { useEffect, useState } from "react";
import PositionDialog from "@/components/Dialog/PositionDialog";
import DropDown from "@/components/DropDown";
import classNames from "classnames";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import Image from "next/image";
import IconClose from "@/components/Icon/IconClose.svg";
import IconCog from "@/components/Icon/IconCog.svg";
import IconArrowCaretDown from "@/components/Icon/IconArrowCaretDown.svg";
import fetchVehicles from "@/lib/typesense/vehicles/fetchVehicles";
import { getAccessoriesQuery, setAccessoriesQuery } from "@/utils/common";

export default function PartsFinderDialog({ open, vehicle, redirect = true, onOpenDialog = () => {} }) {
  const router = useRouter();
  const pathName = usePathname();
  const searchParams = useSearchParams();

  const [yearValue, setYearValue] = useState("");
  const [makeValue, setMakeValue] = useState("");
  const [modelValue, setModelValue] = useState("");

  const [yearOptions, setYearOptions] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const [previousVehicles, setPreviousVehicles] = useState([]);

  const handleSearch = async () => {
    const response = await fetchVehicles({
      filter_by: `year:${yearValue} && make:${makeValue} && model:${modelValue}`,
      include_fields: "",
    });
    if (response.hits.length === 0) return;
    const searchedVehicle =
      response.hits.find(
        vehicle =>
          vehicle.document.year === yearValue &&
          vehicle.document.make === makeValue &&
          vehicle.document.model === modelValue
      )?.document || {};
    const currentHistories = getAccessoriesQuery().histories || [];

    setAccessoriesQuery({
      vehicleNumber: searchedVehicle.vehicleNumber,
      histories: [
        ...currentHistories,
        {
          id: searchedVehicle.id,
          name: searchedVehicle.name,
          make: searchedVehicle.make,
          model: searchedVehicle.model,
          year: searchedVehicle.year,
          vehicleNumber: searchedVehicle.vehicleNumber,
        },
      ],
    });
    if (redirect) {
      router.push(`?vehicle_ids=${searchedVehicle.vehicleNumber}`);
    }
    onOpenDialog(false);
  };

  const removeVehicle = () => {
    if (!vehicle) return;
    const currentHistories = getAccessoriesQuery().histories || [];
    setAccessoriesQuery({
      ...getAccessoriesQuery(),
      vehicleNumber: "",
      histories: [...currentHistories],
    });
    setPreviousVehicles([...currentHistories, vehicle]);
    if (redirect) {
      const params = new URLSearchParams(searchParams.toString());
      params.delete("vehicle_ids");
      if (params.toString()) {
        router.push(`${pathName}?${params.toString()}`);
      } else {
        router.push(`${pathName}`);
      }
    }
    onOpenDialog(false);
  };

  const onSelectVehicle = vehicleNumber => {
    const currentHistories = getAccessoriesQuery().histories || [];

    setAccessoriesQuery({
      vehicleNumber: vehicleNumber,
      histories: [...currentHistories],
    });
    router.push(`?vehicle_ids=${vehicleNumber}`);

    onOpenDialog(false);
  };

  const removePreviousVehicle = vehicleNumber => {
    const currentData = getAccessoriesQuery();
    const newVehicle = currentData.histories.filter(item => item.vehicleNumber !== vehicleNumber);
    setAccessoriesQuery({ ...currentData, histories: newVehicle });
    setPreviousVehicles(newVehicle);
  };

  const fetchYearData = async () => {
    const response = await fetchVehicles({
      facet_by: "year",
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => b.label - a.label);
      setYearOptions(options);
    }
  };

  const fetchMakeData = async year => {
    const response = await fetchVehicles({
      facet_by: "make",
      filter_by: `year:${year}`,
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      setMakeOptions(options);
    }
  };
  const fetchModelData = async (year, make) => {
    const response = await fetchVehicles({
      facet_by: "model",
      filter_by: `year:${year} && make:${make}`,
      include_fields: "",
    });
    if (response.facet_counts.length > 0 && response.facet_counts[0]?.counts.length > 0) {
      const options = response.facet_counts[0]?.counts.map(item => {
        return {
          label: item.value,
          value: item.value,
        };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      setModelOptions(options);
    }
  };

  useEffect(() => {
    if (getAccessoriesQuery()) {
      setPreviousVehicles(getAccessoriesQuery().histories || []);
    }
  }, [vehicle]);

  useEffect(() => {
    if (!open) {
      setYearValue("");
      setMakeValue("");
      setModelValue("");
      setMakeOptions([]);
      setModelOptions([]);
    }
  }, [open]);

  useEffect(() => {
    fetchYearData();
  }, []);

  return (
    <PositionDialog open={open} onChange={onOpenDialog} position="right">
      <div
        className={classNames(
          "max-w-[357px] md:max-w-[478px] w-full md:w-[478px] h-screen  bg-white text-black border-r-[1px] border-grey-100 flex flex-col font-roboto overflow-y-auto pb-[90px] md:pb-0"
        )}
      >
        <div className="h-fit md:h-[120px] bg-black pb-6 pt-6 md:pt-10 px-6 md:px-9 flex  gap-5 md:gap-[22px] items-center ">
          <Image src={IconCog.src} width="40" height="40" alt="IconCog" className="w-6 md:w-10 h-6 md:h-10" />
          <div>
            <p className="text-[26px] md:text-[31px] leading-[26px] md:leading-[31px] text-white font-bold font-rajdhani">
              Bikebiz Parts Finder
            </p>
            <p className="hidden md:block text-xs leading-[20px] text-grey-200">
              Search your motorcycle here to shop products that fit.
            </p>
          </div>
          <Image
            src={IconClose.src}
            width="24"
            height="24"
            alt="IconClose"
            className="w-3 h-3 text-white cursor-auto ml-auto"
            onClick={() => onOpenDialog(false)}
          />
        </div>
        <div className="block md:hidden px-6 py-3 " style={{ background: "#777" }}>
          <p className=" text-sm leading-[20px] text-white">Search your motorcycle here to shop products that fit.</p>
        </div>
        <div className="px-[16px] md:px-8 py-[22px] md:py-7 flex flex-col gap-6">
          <p className="font-rajdhani font-bold text-lg md:text-[26px] -mb-3 md:mb-0">
            Let&#39;s start by searching your vehicle.
          </p>
          <div className="flex flex-col gap-2">
            <p>
              <b>Step 1: </b> Add the year of your motorcycle{" "}
            </p>
            <DropDown
              className="bg-white w-full border-[1px] border-black rounded"
              name="year"
              placeholder="Year"
              value={yearValue}
              options={yearOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width="10" height="10" alt="IconArrowCaretDown" />}
              size={56}
              onChange={e => {
                setMakeValue("");
                setModelValue("");
                setYearValue(e.target.value);
                fetchMakeData(e.target.value);
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <p>
              <b>Step 2: </b> Add the make of your motorcycle{" "}
            </p>
            <DropDown
              className={classNames("bg-white w-full border-[1px] rounded", {
                "border-black": yearValue,
                "border-grey": !yearValue,
              })}
              name="make"
              placeholder="Make"
              value={makeValue}
              options={makeOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width="10" height="10" alt="IconArrowCaretDown" />}
              size={56}
              onChange={e => {
                setModelValue("");
                setMakeValue(e.target.value);
                fetchModelData(yearValue, e.target.value);
              }}
              disabled={!yearValue}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p>
              <b>Step 3: </b> Add the model of your motorcycle{" "}
            </p>
            <DropDown
              className={classNames("bg-white w-full border-[1px] rounded", {
                "border-black": makeValue,
                "border-grey": !makeValue,
              })}
              name="model"
              placeholder="Model"
              value={modelValue}
              options={modelOptions}
              rightIcon={<Image src={IconArrowCaretDown.src} width="10" height="10" alt="IconArrowCaretDown" />}
              size={56}
              onChange={e => setModelValue(e.target.value)}
              disabled={!makeValue}
            />
          </div>
          <button className={classNames("w-full h-[64px] rounded bg-gold font-bold text-base")} onClick={handleSearch}>
            Search Parts
          </button>
          <button className={classNames("text-sm hover:underline mt-[-8px]")} onClick={removeVehicle}>
            Clear Current Vehicle
          </button>
          <div>
            {vehicle && (
              <>
                <div className="text-sm py-2 border-b-[1px] border-grey-100">Current motorcycle</div>
                <div className="flex gap-2 items-center">
                  <div className="w-6 h-6 rounded-full border-[1px] flex justify-center items-center cursor-pointer">
                    <div className="w-2 h-2 rounded-full bg-green"></div>
                  </div>
                  <p className="w-full font-bold text-lg py-[18px] border-b-[1px] border-grey-100">
                    {vehicle.year}, {vehicle.model}
                  </p>
                </div>
              </>
            )}

            {previousVehicles.length > 0 && previousVehicles.find(item => item.id !== vehicle?.id) && (
              <>
                <div className="text-sm py-2 border-b-[1px] border-grey-100 mt-6">Previous motorcycles</div>
                {previousVehicles.map(
                  (item, index) =>
                    (!vehicle || (vehicle && vehicle.id !== item.id)) && (
                      <div
                        key={`previous-vehicle-${index}`}
                        className="flex gap-2 items-center border-b-[1px] border-grey-100"
                      >
                        <div
                          className="w-6 h-6 rounded-full border-[1px] flex justify-center items-center cursor-pointer"
                          onClick={() => {
                            onSelectVehicle(item.vehicleNumber);
                            onOpenDialog(false);
                          }}
                        ></div>
                        <p
                          className="w-full font-bold text-lg py-[18px]  cursor-pointer"
                          onClick={() => {
                            onSelectVehicle(item.vehicleNumber);
                          }}
                        >
                          {item.year}, {item.model}
                        </p>

                        <Image
                          src={IconClose.src}
                          width="24"
                          height="24"
                          alt="IconClose"
                          className="w-3 h-3 ml-auto cursor-pointer"
                          onClick={() => removePreviousVehicle(item.vehicleNumber)}
                        />
                      </div>
                    )
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PositionDialog>
  );
}
