"use client";
import { useEffect } from "react";

export default function FreshChat() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//fw-cdn.com/11272907/3974387.js";
    script.async = true;
    script.chat = "true";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
}
