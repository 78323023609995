import { gql } from "@apollo/client";

export const getWishListQuery = gql`
  query wishListByUser($first: Int, $after: String!) {
    me {
      id
      wishlist(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            product {
              id
            }
          }
        }
        totalCount
      }
    }
  }
`;

export const wishlistAddProductMutation = gql`
  mutation WishlistAddProductMutation($productId: ID!) {
    wishlistAddProduct(productId: $productId) {
      wishlist {
        id
        wishlist {
          id
        }
        product {
          id
        }
      }
      wishlistErrors {
        field
        message
        code
      }
    }
  }
`;

export const wishlistRemoveProductMutation = gql`
  mutation WishlistRemoveProductMutation($productId: ID!) {
    wishlistRemoveProduct(productId: $productId) {
      wishlist {
        id
        wishlist {
          id
        }
        product {
          id
        }
      }
      wishlistErrors {
        field
        message
        code
      }
    }
  }
`;
