import { getWishListQuery } from "@/lib/saleor/queries/wish-list";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export const useAllWishList = (runOnMounted = true) => {
  const [getWishList, { loading, data }] = useLazyQuery(getWishListQuery, {
    fetchPolicy: "cache-and-network",
  });
  const [allWishList, setAllWishList] = useState([]);
  const [rerunQuery, setRerunQuery] = useState(runOnMounted);

  const getWishListByPage = (endCursor = "") => {
    getWishList({
      variables: { first: 100, after: endCursor },
    });
  };

  useEffect(() => {
    if (rerunQuery) {
      getWishListByPage();
    }
  }, [rerunQuery]);

  useEffect(() => {
    if (loading || !data) return;

    if (data?.me) {
      setAllWishList([...allWishList, ...data.me.wishlist.edges]);
    }
    if (!data?.me?.wishlist?.pageInfo?.hasNextPage) return;

    const endCursor = data?.me?.wishlist?.pageInfo?.endCursor;
    getWishListByPage(endCursor);
  }, [loading]);

  return { allWishList, setRerunQuery };
};
