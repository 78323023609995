"use client";
import { useState, useEffect } from "react";
import { useAllWishList } from "@/hooks/AllWishList";
import { wishlistAddProductMutation, wishlistRemoveProductMutation } from "@/lib/saleor/queries/wish-list";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/navigation";
import Image from "next/image";
import IconHeart from "@/components/Icon/IconHeart.svg";
import IconHeartRed from "@/components/Icon/IconHeartRed.svg";
import { getCookie } from "@/services/cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function WishList({ product, width = 11, height = 11, rounded = true }) {
  const router = useRouter();

  const [addProductToWishlist] = useMutation(wishlistAddProductMutation);
  const [removeProductWishList] = useMutation(wishlistRemoveProductMutation);
  const [onWishList, setOnWishList] = useState(false);
  const { setRerunQuery, allWishList } = useAllWishList();

  const findIndexProduct = () => {
    return allWishList.findIndex(item => product.id === item.node.product.id);
  };

  useEffect(() => {
    if (!allWishList || !product) return;
    const index = findIndexProduct();
    setOnWishList(index != -1);
  }, [allWishList?.length, JSON.stringify(product || {})]);

  const handleAddWishList = async e => {
    if (e) e.stopPropagation();
    const token = getCookie("token");
    if (!token) {
      router.push("/login");
    } else {
      handleAddProductToWishList();
    }
  };

  const handleAddProductToWishList = async e => {
    if (e) e.stopPropagation();

    const res = await addProductToWishlist({
      variables: {
        productId: product.id,
      },
    });
    if (
      res?.data?.wishlistAddProduct?.wishlistErrors &&
      Array.isArray(res?.data?.wishlistAddProduct?.wishlistErrors) &&
      res?.data?.wishlistAddProduct?.wishlistErrors.length > 0
    ) {
      toast.error(`Add product to wishlist error, Please try again later`);
    } else {
      setOnWishList(true);
      toast.success(`${product.name} added to wishlist`);
      setRerunQuery(true);
    }
  };

  const handleRemoveWishlist = async () => {
    const res = await removeProductWishList({
      variables: {
        productId: product.id,
      },
    });
    if (
      res?.data?.wishlistRemoveProduct?.wishlistErrors &&
      Array.isArray(res.data.wishlistRemoveProduct.wishlistErrors) &&
      res.data.wishlistRemoveProduct.wishlistErrors.length > 0
    ) {
      toast.error(`Remove product to wishlist error, Please try again later`);
    } else {
      setOnWishList(false);
      toast.success(`${product.name || ""} removed from wishlist`);
      setRerunQuery(true);
    }
  };

  return (
    <>
      <ToastContainer />
      {rounded ? (
        <div
          onClick={e => {
            e.preventDefault();
            if (onWishList) {
              handleRemoveWishlist();
            } else {
              handleAddWishList();
            }
          }}
          className="cursor-pointer text-grey ml-auto p-[10px] border-[1px] border-light-grey rounded-full hover:border-black z-[1]"
        >
          {!onWishList ? (
            <Image src={IconHeart.src} alt="Heart" width={width} height={height} />
          ) : (
            <Image src={IconHeartRed.src} alt="Heart Red" width={width} height={height} />
          )}
        </div>
      ) : !onWishList ? (
        <Image
          src={IconHeart.src}
          alt="Heart"
          width={width}
          height={height}
          className="cursor-pointer"
          onClick={e => {
            e.preventDefault();
            handleAddWishList();
          }}
        />
      ) : (
        <Image
          src={IconHeartRed.src}
          alt="Heart Red"
          width={width}
          height={height}
          className="cursor-pointer"
          onClick={e => {
            e.preventDefault();
            handleRemoveWishlist();
          }}
        />
      )}
    </>
  );
}
