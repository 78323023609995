"use client";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { useEffect } from "react";

export default function Component({ className, open, onChange, children, position }) {
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [open]);
  return (
    <div className={`${styles.slide} ${open ? styles.show : ""}`}>
      <div className={classNames(className, styles.content, styles[position])}>{children}</div>
      <div className={classNames(styles.overlay)} onClick={() => onChange(false)}></div>
    </div>
  );
}
